// src/pages/Profile.tsx
import { useState, useEffect } from 'react';
import { useUser, useClerk, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { 
  User,
  LogOut,
  Trophy,
  Star,
  Calendar,
  AlertCircle,
  Users
} from 'lucide-react';
import api from '../lib/api';
import type { ModelVariant } from '../types';
import { FriendsTab } from '../components/FriendsTab';

interface ProfileViewProps {
  defaultTab?: 'friends' | 'profile';
}

export default function Profile({ defaultTab = 'profile' }: ProfileViewProps) {
  const { user } = useUser();
  const { signOut } = useClerk();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState<'profile' | 'friends'>(defaultTab);
  const [stats, setStats] = useState({
    totalOwned: 0,
    favoritesSeries: '',
    recentAcquisitions: [] as ModelVariant[]
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = await getToken();
        const [collectionRes, modelsRes] = await Promise.all([
          api.get('/api/collection', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }),
          api.get('/api/models?count_only=true')
        ]);

        const collection = collectionRes.data.models;
        const total = modelsRes.data.total;

        // Calculate favorite series
        const seriesCounts = collection.reduce((acc: Record<string, number>, model: ModelVariant) => {
          acc[model.series] = (acc[model.series] || 0) + 1;
          return acc;
        }, {});

        const favoriteSeries = Object.entries(seriesCounts)
          .sort(([, countA], [, countB]) => (countB as number) - (countA as number))[0]?.[0] || '';

        // Get recent acquisitions
        const recentAcquisitions = [...collection]
          .sort((a, b) => {
            const dateA = a.acquired_date ? new Date(a.acquired_date).getTime() : 0;
            const dateB = b.acquired_date ? new Date(b.acquired_date).getTime() : 0;
            return dateB - dateA;
          })
          .slice(0, 3);

        setStats({
          totalOwned: collection.length,
          favoritesSeries: favoriteSeries,
          recentAcquisitions
        });
      } catch (error) {
        console.error('Failed to fetch stats:', error);
        setError('Failed to load profile data');
      }
    };

    if (user) {
      fetchStats();
    }
  }, [getToken, user]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/sign-in');
    } catch (err) {
      setError('Failed to sign out');
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-6 pb-20">
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 text-red-500 dark:text-red-200 p-4 rounded-xl mb-4 flex items-center gap-2">
          <AlertCircle className="h-5 w-5 flex-shrink-0" />
          <span>{error}</span>
        </div>
      )}

      {/* Profile Header */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 mb-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <div className="bg-blue-100 dark:bg-blue-900 rounded-full p-4">
              <User className="h-8 w-8 text-blue-500 dark:text-blue-300" />
            </div>
            <div>
              <h2 className="text-xl font-semibold dark:text-white">
                {user?.username || user?.primaryEmailAddress?.emailAddress}
              </h2>
              <p className="text-gray-500 dark:text-gray-400">
                {user?.primaryEmailAddress?.emailAddress}
              </p>
            </div>
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="flex gap-4 border-b border-gray-200 dark:border-gray-700 mb-6">
          <button
            onClick={() => setActiveTab('profile')}
            className={`pb-3 px-1 relative ${
              activeTab === 'profile'
                ? 'text-blue-500 dark:text-blue-400'
                : 'text-gray-500 dark:text-gray-400'
            }`}
          >
            Profile
            {activeTab === 'profile' && (
              <span className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 dark:bg-blue-400" />
            )}
          </button>
          <button
            onClick={() => setActiveTab('friends')}
            className={`pb-3 px-1 relative ${
              activeTab === 'friends'
                ? 'text-blue-500 dark:text-blue-400'
                : 'text-gray-500 dark:text-gray-400'
            }`}
          >
            Friends
            {activeTab === 'friends' && (
              <span className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 dark:bg-blue-400" />
            )}
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === 'profile' ? (
          <>
            {/* Collection Stats */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Trophy className="h-5 w-5 text-blue-500 dark:text-blue-400" />
                  <h3 className="text-sm font-medium text-gray-600 dark:text-gray-300">Collection Size</h3>
                </div>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.totalOwned}</p>
              </div>

              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Star className="h-5 w-5 text-yellow-500" />
                  <h3 className="text-sm font-medium text-gray-600 dark:text-gray-300">Favorite Series</h3>
                </div>
                <p className="text-lg font-semibold text-gray-900 dark:text-white truncate">
                  {stats.favoritesSeries || 'No series yet'}
                </p>
              </div>

              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Calendar className="h-5 w-5 text-green-500" />
                  <h3 className="text-sm font-medium text-gray-600 dark:text-gray-300">Recent Additions</h3>
                </div>
                <div className="space-y-1">
                  {stats.recentAcquisitions.map(model => (
                    <p key={model.id} className="text-sm text-gray-700 dark:text-gray-300 truncate">
                      {model.name}
                    </p>
                  ))}
                  {stats.recentAcquisitions.length === 0 && (
                    <p className="text-sm text-gray-500 dark:text-gray-400">No recent additions</p>
                  )}
                </div>
              </div>
            </div>

            {/* Quick Links */}
            <div className="border-t dark:border-gray-700 pt-6">
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={() => navigate('/stats')}
                  className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors text-left"
                >
                  <h3 className="font-medium text-gray-900 dark:text-white mb-1">View Statistics</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">See detailed collection stats</p>
                </button>
                <button
                  onClick={() => navigate('/search')}
                  className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors text-left"
                >
                  <h3 className="font-medium text-gray-900 dark:text-white mb-1">Browse Models</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Discover new Hot Wheels</p>
                </button>
              </div>
            </div>
          </>
        ) : (
          <FriendsTab />
        )}
      </div>

      <button 
        onClick={handleSignOut}
        className="w-full bg-red-500 p-4 rounded-xl shadow-lg flex items-center justify-center space-x-2 hover:bg-red-600 text-white transition-colors"
      >
        <LogOut className="h-5 w-5" />
        <span>Sign Out</span>
      </button>
    </div>
  );
}