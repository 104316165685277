// src/pages/FriendCollection.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { InventoryView } from '../components/InventoryView';
import { Header } from '../components/Header';
import { Loader2, ArrowLeft } from 'lucide-react';
import type { ModelVariant, ViewMode } from '../types';
import api from '../lib/api';
import { useAuth } from '@clerk/clerk-react';

export default function FriendCollection() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [models, setModels] = useState<ModelVariant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [friendInfo, setFriendInfo] = useState<{ username: string; collectionCount: number } | null>(null);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchFriendCollection = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await api.get(`/api/friends/${username}/collection`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setModels(data.models);
        setFriendInfo({
          username: data.username,
          collectionCount: data.models.length,
        });
      } catch (error) {
        console.error("Failed to fetch friend's collection:", error);
        setError('Failed to load collection');
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchFriendCollection();
    }
  }, [username, getToken]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error || !friendInfo) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-500 dark:text-gray-400 mb-4">
            {error || 'Friend not found'}
          </p>
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center gap-2 text-blue-500 hover:text-blue-600"
          >
            <ArrowLeft className="h-4 w-4" />
            Go back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pb-20">
      <Header
        title={`${friendInfo.username}'s Collection`}
        viewMode={viewMode}
        onViewChange={setViewMode}
        backButton
      />
      <InventoryView
        models={models}
        onToggleOwned={() => {}} // No-op since this is read-only
        onEditNotes={async () => {}} // No-op async function
        viewMode={viewMode}
        readOnly
      />
    </div>
  );
}
