import React, { useState, useEffect } from 'react';
import { 
  User as UserIcon,
  LogOut,
  Trophy,
  Star,
  Calendar,
  AlertCircle,
  Users,
  UserPlus,
  UserMinus,
  Clock,
  Search,
  Check,
  X,
  Loader2
} from 'lucide-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import type { Friend, FriendRequest, User } from '../types';

export function FriendsTab() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [pendingRequests, setPendingRequests] = useState<FriendRequest[]>([]);
  const [sentRequests, setSentRequests] = useState<FriendRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchFriends();
    fetchPendingRequests();
    fetchSentRequests();
  }, []);

  const fetchFriends = async () => {
    try {
      const { data } = await axios.get('/api/friends');
      setFriends(data.friends);
    } catch (error) {
      console.error('Failed to fetch friends:', error);
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const { data } = await axios.get('/api/friends/requests/received');
      setPendingRequests(data.requests);
    } catch (error) {
      console.error('Failed to fetch pending requests:', error);
    }
  };

  const fetchSentRequests = async () => {
    try {
      const { data } = await axios.get('/api/friends/requests/sent');
      setSentRequests(data.requests);
    } catch (error) {
      console.error('Failed to fetch sent requests:', error);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const { data } = await axios.get(`/api/users/search?q=${encodeURIComponent(searchQuery)}`);
      const filteredUsers = data.users.filter((user: User) => {
        const isAlreadyFriend = friends.some(friend => friend.id === user.id);
        const hasPendingRequest = pendingRequests.some(req => req.senderId === user.id);
        const hasSentRequest = sentRequests.some(req => req.receiverId === user.id);
        return !isAlreadyFriend && !hasPendingRequest && !hasSentRequest;
      });
      setSearchResults(filteredUsers);
    } catch (error) {
      console.error('Search failed:', error);
      setError('Failed to search for users');
    } finally {
      setLoading(false);
    }
  };

  const sendFriendRequest = async (userId: string) => {
    try {
      const { data } = await axios.post('/api/friends/requests', { receiverId: userId });
      setSentRequests(prev => [...prev, data.request]);
      setSearchResults(prev => prev.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Failed to send friend request:', error);
      setError('Failed to send friend request');
    }
  };

  const handleRequest = async (requestId: string, accept: boolean) => {
    try {
      await axios.put(`/api/friends/requests/${requestId}`, { accept });
      if (accept) {
        fetchFriends();
      }
      setPendingRequests(prev => prev.filter(req => req.id !== requestId));
    } catch (error) {
      console.error('Failed to handle friend request:', error);
      setError('Failed to handle friend request');
    }
  };

  const removeFriend = async (friendId: string) => {
    try {
      await axios.delete(`/api/friends/${friendId}`);
      setFriends(prev => prev.filter(friend => friend.id !== friendId));
    } catch (error) {
      console.error('Failed to remove friend:', error);
      setError('Failed to remove friend');
    }
  };

  const formatDate = (timestamp: number | string | null) => {
    if (!timestamp) return 'Never';
  
    try {
      const timestampNum = typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp;
      const date = new Date(timestampNum * 1000);
  
      if (isNaN(date.getTime())) {
        return 'Never';
      }
  
      const now = new Date();
      const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
      if (diffInSeconds < 60) {
        return 'Just now';
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else {
        return new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }).format(date);
      }
    } catch (error) {
      console.error('Date parsing error:', error);
      return 'Never';
    }
  };

  return (
    <div className="space-y-6">
      {/* Search Bar */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-4">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            placeholder="Search for users..."
            className="w-full pl-12 pr-32 py-4 bg-gray-100 dark:bg-gray-700/50 text-gray-900 dark:text-white 
                     placeholder-gray-500 dark:placeholder-gray-400 rounded-xl border-none focus:ring-2 
                     focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors"
          />
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
          <button
            onClick={handleSearch}
            disabled={loading}
            className="absolute right-2 top-1/2 -translate-y-1/2 px-6 py-2 bg-blue-500 text-white font-medium
                     rounded-lg hover:bg-blue-600 disabled:opacity-50 transition-colors"
          >
            {loading ? <Loader2 className="h-5 w-5 animate-spin" /> : 'Search'}
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500/20 text-red-500 p-4 rounded-xl">
          {error}
        </div>
      )}

      {/* Search Results */}
      {searchResults.length > 0 && (
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Search Results</h3>
          </div>
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {searchResults.map(user => (
              <div key={user.id} className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">{user.username}</p>
                </div>
                <button
                  onClick={() => sendFriendRequest(user.id)}
                  className="p-2 text-blue-500 hover:bg-blue-50 dark:hover:bg-blue-500/20 
                           rounded-xl transition-colors"
                >
                  <UserPlus className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Pending Requests */}
      {pendingRequests.length > 0 && (
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex items-center gap-3">
            <Clock className="h-5 w-5 text-blue-500" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Pending Requests</h3>
          </div>
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {pendingRequests.map(request => (
              <div key={request.id} className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">From: {request.senderUsername}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {formatDate(request.createdAt)}
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleRequest(request.id, true)}
                    className="p-2 text-green-500 hover:bg-green-50 dark:hover:bg-green-500/20 
                             rounded-xl transition-colors"
                  >
                    <Check className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleRequest(request.id, false)}
                    className="p-2 text-red-500 hover:bg-red-50 dark:hover:bg-red-500/20 
                             rounded-xl transition-colors"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Sent Requests */}
      {sentRequests.length > 0 && (
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex items-center gap-3">
            <Clock className="h-5 w-5 text-blue-500" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Sent Requests</h3>
          </div>
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {sentRequests.map(request => (
              <div key={request.id} className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">To: {request.receiverUsername}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {formatDate(request.createdAt)}
                  </p>
                </div>
                <span className="text-sm px-3 py-1 bg-yellow-500/10 text-yellow-500 rounded-full">
                  Pending
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Friends List */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex items-center gap-3">
          <Users className="h-5 w-5 text-blue-500" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Friends</h3>
        </div>
        {friends.length === 0 ? (
          <div className="p-8 text-center">
            <p className="text-gray-500 dark:text-gray-400">
              No friends added yet. Search for users to add them as friends!
            </p>
          </div>
        ) : (
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {friends.map(friend => (
              <div key={friend.id} className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <div className="flex-1">
                  <p className="font-medium text-gray-900 dark:text-white">{friend.username}</p>
                  <div className="flex items-center gap-2 mt-1">
                    <Link
                      to={`/friends/${friend.username}/collection`}
                      className="text-sm text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                      {friend.collectionCount} models
                    </Link>
                    <span className="text-gray-300 dark:text-gray-600">•</span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Active {formatDate(friend.lastActive)}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => removeFriend(friend.id)}
                  className="p-2 text-red-500 hover:bg-red-50 dark:hover:bg-red-500/20 
                           rounded-xl transition-colors"
                >
                  <UserMinus className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}