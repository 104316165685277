// src/pages/ModelView.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ModelDetailsModal } from '../components/ModelDetailsModal';
import type { ModelVariant } from '../types';
import api from '../lib/api';
import { useAuth } from '@clerk/clerk-react';

export default function ModelView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isSignedIn, getToken } = useAuth();
  const [model, setModel] = useState<ModelVariant | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchModel = async () => {
      try {
        setLoading(true);
        setError(null);
        const { data } = await api.get(`/api/share/${id}`);
        setModel(data);
      } catch (error) {
        console.error('Failed to fetch model:', error);
        setError('Failed to load model details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchModel();
    }
  }, [id]);

  const handleToggleOwned = async () => {
    if (!isSignedIn || !model) {
      navigate('/sign-in');
      return;
    }

    try {
      const token = await getToken();
      if (model.owned) {
        await api.delete(`/api/collection/${model.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await api.post(`/api/collection/${model.id}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setModel((prev) => (prev ? { ...prev, owned: !prev.owned } : null));
    } catch (error) {
      console.error('Failed to update collection:', error);
    }
  };

  const handleEditNotes = async (notes: string) => {
    if (!isSignedIn || !model) {
      navigate('/sign-in');
      return;
    }

    try {
      const token = await getToken();
      await api.put(
        `/api/collection/${model.id}/notes`,
        { notes },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setModel((prev) => (prev ? { ...prev, notes } : null));
    } catch (error) {
      console.error('Failed to update notes:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !model) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-500 dark:text-gray-400">
            {error || 'Model not found.'}
          </p>
          <button
            onClick={() => navigate('/search')}
            className="mt-4 text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
          >
            Return to search
          </button>
        </div>
      </div>
    );
  }

  return (
    <ModelDetailsModal
      model={model}
      isOpen={true}
      onClose={() => navigate(-1)}
      onToggleOwned={handleToggleOwned}
      onEditNotes={handleEditNotes}
      showShare={true}
      readOnly={!isSignedIn}
    />
  );
}
