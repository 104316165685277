// src/pages/Search.tsx
import { useState } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { SearchView } from '../components/SearchView';
import { Header } from '../components/Header';
import type { ViewMode } from '../types';
import api from '../lib/api';

export default function Search() {
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const { isSignedIn, isLoaded } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const handleToggleOwned = async (id: string) => {
    if (!isSignedIn) {
      navigate('/sign-in');
      return;
    }

    try {
      const token = await getToken();
      await api.post(
        `/api/collection/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Failed to update collection:', error);
    }
  };

  const handleEditNotes = async (id: string, notes: string) => {
    if (!isSignedIn) {
      navigate('/sign-in');
      return;
    }

    try {
      const token = await getToken();
      await api.put(
        `/api/collection/${id}/notes`,
        { notes },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Failed to update notes:', error);
    }
  };

  // Don't render until auth state is loaded
  if (!isLoaded) {
    return null;
  }

  return (
    <div className="min-h-screen pb-20">
      <Header
        title="Search Models"
        viewMode={viewMode}
        onViewChange={setViewMode}
      />
      <SearchView
        viewMode={viewMode}
        onToggleOwned={handleToggleOwned}
        onEditNotes={handleEditNotes}
        isAuthenticated={isSignedIn}
      />
    </div>
  );
}
