// src/pages/Collection.tsx
import React, { useState, useEffect } from 'react';
import { InventoryView } from '../components/InventoryView';
import { Header } from '../components/Header';
import type { ModelVariant, ViewMode } from '../types';
import api from '../lib/api';
import { useAuth } from '@clerk/clerk-react';

export default function Collection() {
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [models, setModels] = useState<ModelVariant[]>([]);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const token = await getToken();
        const { data } = await api.get('/api/collection', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setModels(data.models);
      } catch (error) {
        console.error('Failed to fetch collection:', error);
      }
    };
    fetchCollection();
  }, [getToken]);

  const handleToggleOwned = async (id: string) => {
    try {
      const token = await getToken();
      const model = models.find((m) => m.id === id);
      if (model?.owned) {
        await api.delete(`/api/collection/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await api.post(`/api/collection/${id}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setModels((prevModels) =>
        prevModels.map((m) => (m.id === id ? { ...m, owned: !m.owned } : m))
      );
    } catch (error) {
      console.error('Failed to update collection:', error);
    }
  };

  const handleEditNotes = async (id: string, notes: string) => {
    try {
      const token = await getToken();
      await api.put(
        `/api/collection/${id}/notes`,
        { notes },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setModels((prevModels) =>
        prevModels.map((m) => (m.id === id ? { ...m, notes } : m))
      );
    } catch (error) {
      console.error('Failed to update notes:', error);
    }
  };

  return (
    <div className="min-h-screen pb-20">
      <Header
        title="My Collection"
        viewMode={viewMode}
        onViewChange={setViewMode}
        showFriendsDropdown={true}
      />
      <InventoryView
        models={models}
        onToggleOwned={handleToggleOwned}
        onEditNotes={handleEditNotes}
        viewMode={viewMode}
      />
    </div>
  );
}
