import { useUser } from "@clerk/clerk-react";
import { Link, useLocation } from 'react-router-dom';
import { Car, Search, BarChart2, User } from 'lucide-react';

export default function Navbar() {
  const { isSignedIn, isLoaded } = useUser();
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;

  // Don't show navbar on sign-in/sign-up pages
  if (location.pathname.startsWith('/sign-')) {
    return null;
  }

  // Show nothing while loading auth state
  if (!isLoaded) {
    return null;
  }

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg border-t border-gray-200 dark:border-gray-700 z-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-around">
          {isSignedIn ? (
            <>
              <Link
                to="/"
                className={`flex-1 flex flex-col items-center py-3 ${
                  isActive('/') 
                    ? 'text-blue-500 dark:text-blue-400' 
                    : 'text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'
                }`}
              >
                <Car className="h-6 w-6" />
                <span className="text-xs mt-1">Collection</span>
                {isActive('/') && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-blue-500 dark:bg-blue-400" />
                )}
              </Link>
              <Link
                to="/search"
                className={`flex-1 flex flex-col items-center py-3 ${
                  isActive('/search')
                    ? 'text-blue-500 dark:text-blue-400'
                    : 'text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'
                }`}
              >
                <Search className="h-6 w-6" />
                <span className="text-xs mt-1">Search</span>
                {isActive('/search') && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-blue-500 dark:bg-blue-400" />
                )}
              </Link>
              <Link
                to="/stats"
                className={`flex-1 flex flex-col items-center py-3 ${
                  isActive('/stats')
                    ? 'text-blue-500 dark:text-blue-400'
                    : 'text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'
                }`}
              >
                <BarChart2 className="h-6 w-6" />
                <span className="text-xs mt-1">Stats</span>
                {isActive('/stats') && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-blue-500 dark:bg-blue-400" />
                )}
              </Link>
              <Link
                to="/profile"
                className={`flex-1 flex flex-col items-center py-3 ${
                  isActive('/profile')
                    ? 'text-blue-500 dark:text-blue-400'
                    : 'text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'
                }`}
              >
                <User className="h-6 w-6" />
                <span className="text-xs mt-1">Profile</span>
                {isActive('/profile') && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-blue-500 dark:bg-blue-400" />
                )}
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/search"
                className={`flex-1 flex flex-col items-center py-3 ${
                  isActive('/search')
                    ? 'text-blue-500 dark:text-blue-400'
                    : 'text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'
                }`}
              >
                <Search className="h-6 w-6" />
                <span className="text-xs mt-1">Search</span>
                {isActive('/search') && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-blue-500 dark:bg-blue-400" />
                )}
              </Link>
              <Link
                to="/sign-in"
                className={`flex-1 flex flex-col items-center py-3 ${
                  isActive('/sign-in')
                    ? 'text-blue-500 dark:text-blue-400'
                    : 'text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'
                }`}
              >
                <User className="h-6 w-6" />
                <span className="text-xs mt-1">Sign In</span>
                {isActive('/sign-in') && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-blue-500 dark:bg-blue-400" />
                )}
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}