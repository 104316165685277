// src/main.tsx
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter } from 'react-router-dom';
import { dark } from '@clerk/themes';
import App from './App';
import './index.css';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ClerkProvider 
      publishableKey={PUBLISHABLE_KEY}
      appearance={{
        baseTheme: dark,
        variables: {
          colorPrimary: '#3b82f6',
          colorTextOnPrimaryBackground: 'white',
        },
        elements: {
          formButtonPrimary: 
            "bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg",
          socialButtonsBlockButton: 
            "w-full border border-gray-300 dark:border-gray-600 rounded-lg",
          card: "bg-white dark:bg-gray-800 shadow-xl rounded-xl",
          headerTitle: "text-2xl font-bold",
          headerSubtitle: "text-gray-600 dark:text-gray-400",
        }
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ClerkProvider>
  </StrictMode>
);