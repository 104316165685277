// src/components/ModelDetailsModal.tsx
import React, { useEffect, useRef, useState } from 'react';
import { X, Check, Edit3, Plus, Share2, User, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { ModelVariant } from '../types';
import { NotesEditor } from './NotesEditor';

interface ModelDetailsModalProps {
  model: ModelVariant;
  isOpen: boolean;
  onClose: () => void;
  onToggleOwned: (id: string) => void;
  onEditNotes: (id: string, notes: string) => Promise<void>;
  showShare?: boolean;
  readOnly?: boolean;
}

export function ModelDetailsModal({ 
  model, 
  isOpen, 
  onClose, 
  onToggleOwned, 
  onEditNotes,
  readOnly = false,
  showShare = false
}: ModelDetailsModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isOwned, setIsOwned] = useState(model.owned);
  const [showNotesEditor, setShowNotesEditor] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);
  const [shareError, setShareError] = useState<string | null>(null);

  useEffect(() => {
    setIsOwned(model.owned);
  }, [model.owned]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  const handleToggleOwned = () => {
    setIsOwned(!isOwned);
    onToggleOwned(model.id);
  };

  const handleShare = async () => {
    try {
      const shareUrl = `${window.location.origin}/model/${model.id}`;
      
      if ('share' in navigator && typeof navigator.share === 'function') {
        await navigator.share({
          title: model.name,
          text: `Check out this Hot Wheels model: ${model.name}`,
          url: shareUrl
        });
        setShareSuccess(true);
      } else {
        await navigator.clipboard.writeText(shareUrl);
        setShareSuccess(true);
        setTimeout(() => setShareSuccess(false), 2000);
      }
      setShareError(null);
    } catch (error) {
      console.error('Error sharing:', error);
      if (error instanceof Error) {
        setShareError(error.message);
      } else {
        setShareError('Failed to share');
      }
      setTimeout(() => setShareError(null), 3000);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black/50 backdrop-blur-sm flex items-start justify-center">
      <div 
        ref={modalRef}
        className="relative bg-white dark:bg-gray-900 rounded-2xl shadow-2xl w-full max-w-4xl mx-auto my-4 overflow-hidden"
        style={{ 
          maxHeight: 'calc(100vh - 5rem)',
          margin: '1rem 1rem 5rem 1rem'
        }}
      >
        <div className="sticky top-0 z-10 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
          <div className="flex justify-between items-center p-6">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{model.name}</h2>
            <div className="flex items-center gap-2">
              {showShare && (
                <button
                  onClick={handleShare}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors relative group"
                  title="Share model"
                >
                  <Share2 className="h-6 w-6 text-gray-400" />
                  {shareSuccess && (
                    <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-sm text-white bg-black rounded whitespace-nowrap">
                      {('share' in navigator && typeof navigator.share === 'function') ? 'Shared!' : 'Copied!'}
                    </span>
                  )}
                </button>
              )}
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
                title="Close"
              >
                <X className="h-6 w-6 text-gray-400" />
              </button>
            </div>
          </div>
        </div>

        {shareError && (
          <div className="mx-6 mb-4 p-4 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg flex items-center gap-2 text-red-700 dark:text-red-400">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <span>{shareError}</span>
          </div>
        )}

        <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 13rem)' }}>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="relative">
                <div className="relative rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800">
                  <img
                    src={model.image_url}
                    alt={model.name}
                    className="w-full h-auto object-contain"
                    onError={(e) => {
                      e.currentTarget.src = 'https://images.unsplash.com/photo-1594787318286-3d835c1d207f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3';
                    }}
                    loading="lazy"
                  />
                </div>
                {!readOnly ? (
                  <button
                    onClick={handleToggleOwned}
                    className={`absolute top-4 right-4 p-2 rounded-full shadow-lg transition-colors ${
                      isOwned
                        ? 'bg-green-500 text-white hover:bg-green-600'
                        : 'bg-gray-200 dark:bg-gray-700 text-gray-500 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
                    }`}
                    title={isOwned ? "Remove from collection" : "Add to collection"}
                  >
                    {isOwned ? <Check className="h-5 w-5" /> : <Plus className="h-5 w-5" />}
                  </button>
                ) : (
                  <Link
                    to="/auth"
                    className="absolute top-4 right-4 p-2 rounded-full shadow-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                    title="Sign in to collect"
                  >
                    <User className="h-5 w-5" />
                  </Link>
                )}
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Details</h3>
                  <dl className="space-y-3">
                    <div>
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Series</dt>
                      <dd className="text-gray-900 dark:text-white">
                        {model.series}
                      </dd>
                      {model.series_number && (
                        <dd className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                          #{model.series_number}
                        </dd>
                      )}
                    </div>
                    <div>
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Year</dt>
                      <dd className="text-gray-900 dark:text-white">{model.year}</dd>
                    </div>
                    <div>
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Collection Number</dt>
                      <dd className="text-gray-900 dark:text-white">{model.collection_number}</dd>
                    </div>
                    <div>
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Toy Number</dt>
                      <dd className="text-gray-900 dark:text-white">{model.toyNumber}</dd>
                    </div>
                    <div>
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Color</dt>
                      <dd className="text-gray-900 dark:text-white">{model.color}</dd>
                    </div>
                    {model.wheel_type && (
                      <div>
                        <dt className="text-sm text-gray-500 dark:text-gray-400">Wheels</dt>
                        <dd className="text-gray-900 dark:text-white">{model.wheel_type}</dd>
                      </div>
                    )}
                    {model.base_color && (
                      <div>
                        <dt className="text-sm text-gray-500 dark:text-gray-400">Base Color</dt>
                        <dd className="text-gray-900 dark:text-white">{model.base_color}</dd>
                      </div>
                    )}
                    {model.window_color && (
                      <div>
                        <dt className="text-sm text-gray-500 dark:text-gray-400">Window Color</dt>
                        <dd className="text-gray-900 dark:text-white">{model.window_color}</dd>
                      </div>
                    )}
                    {model.interior_color && (
                      <div>
                        <dt className="text-sm text-gray-500 dark:text-gray-400">Interior Color</dt>
                        <dd className="text-gray-900 dark:text-white">{model.interior_color}</dd>
                      </div>
                    )}
                    {model.country_made && (
                      <div>
                        <dt className="text-sm text-gray-500 dark:text-gray-400">Made in</dt>
                        <dd className="text-gray-900 dark:text-white">{model.country_made}</dd>
                      </div>
                    )}
                  </dl>
                </div>

                {model.tampos && model.tampos.length > 0 && (
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Tampos</h3>
                    <ul className="list-disc list-inside space-y-1">
                      {model.tampos.map((tampo, index) => (
                        <li key={index} className="text-gray-700 dark:text-gray-300">{tampo}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {!readOnly && (
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Notes</h3>
                      <button
                        onClick={() => setShowNotesEditor(true)}
                        className="text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-300 flex items-center gap-1"
                      >
                        <Edit3 className="h-4 w-4" />
                        <span>Edit</span>
                      </button>
                    </div>
                    <p className="text-gray-700 dark:text-gray-300 italic">
                      {model.notes || 'No notes added yet.'}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showNotesEditor && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
              <NotesEditor
                initialNotes={model.notes}
                onSave={(notes) => {
                  onEditNotes(model.id, notes);
                  setShowNotesEditor(false);
                }}
                onClose={() => setShowNotesEditor(false)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}