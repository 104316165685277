import { useUser } from "@clerk/clerk-react";
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const { isSignedIn, isLoaded } = useUser();
  const location = useLocation();
  
  // Show nothing while loading
  if (!isLoaded) {
    return null;
  }
  
  if (!isSignedIn) {
    // Save the attempted URL for redirecting after login
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}